div{
    font-family: MabryPro;
}

input, table, select, .box{
    -webkit-box-shadow: 5px 5px 1px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 1px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 1px 0px rgba(0,0,0,0.75);
}

table{
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
}